<template>
  <div class="steps" v-if="stepData">
    <div class="step-items">
      <div
          class="step"
          v-for="item in stepData.stages"
          :key="item.name"
          :content="item.name"
          :style="`flex-basis: ${stepWidth}`"
      >
        <div class="step-main" @click="$emit('clickStepItem', {...item,'currentStage':stepData.currentStage})">
          <div class="step-title">
            {{ item.name }}
          </div>
          <state-icon :type="getIconType(item)"/>
        </div>
        <div class="step-line"></div>
      </div>
    </div>

    <div @click="$emit('clickStepItem', {...stepData.abort, 'currentStage':stepData.currentStage})"
         class="termination-step">
      <state-icon :type="getIconType(stepData.abort)"/>
      <div class="step-title">
        {{ stepData.abort.name }}
      </div>
    </div>
  </div>
</template>

<script>
import StateIcon from "@/components/Apply/ApplyStateIcon.vue";
import {IconType, Operation, OperationStatus} from "@/api/consts";

export default {
  components: {StateIcon},
  props: ["stepData"],
  data() {
    return {
      iconSize: 18,
    };
  },
  computed: {
    stepWidth() {
      return 100 / (this.stepData.stages.length - 1) + "%";
    },
  },
  methods: {
    // getCurrentStage() {
    //   if (this.stepData) {
    //     var currentStage = null;
    //     // 遍历stages，operation不为1就结束 为1就继续
    //     for (var i = 0; i < this.stepData.stages.length; i++) {
    //       if (this.stepData.stages[i].operationStatus === 1) {
    //         // currentStage = this.stepData.stages[i]["stage"];
    //         currentStage = this.stepData.stages[i];
    //       } else {
    //         break;
    //       }
    //     }
    //     return currentStage;
    //   } else {
    //     return null;
    //   }
    // },
    getApproveInfo(item) {
      const {approveState = "", approveOperateName = "", approveId} = item;

      if (!approveId) {
        return;
      } else {
        return {
          state: approveState == 0 ? "待审核" : "审核拒绝",
          style:
              approveState == 0
                  ? "--color: var(--secondMainColor)"
                  : "--color: var(--red)",
          name: approveOperateName,
        };
      }
    },

    getIconType(item) {
      // icon解释
      // 1是当前或已经历阶段
      // 2是审核被拒
      // 3是我可以查看审核
      // 4是还没到达
      // 5是无法操作
      // 6是审核通过但尚未完成转阶段
      console.log(item)
      switch (item.operationStatus) {
        case OperationStatus.OPERATION_ALLOWED:
          return IconType.REACHABLE;
        case OperationStatus.OPERATION_DONE:
          return IconType.DONE_OR_CURRENT;
        case OperationStatus.OPERATION_NOT_ALLOWED:
          return IconType.NOT_ALLOWED;
        case OperationStatus.OPERATION_TO_BE_APPROVED:
          return IconType.ABLE_TO_VIEW_APPROVE;
        case OperationStatus.OPERATION_TO_BE_CONFIRMED:
          return IconType.DONE_BUT_NOT_REACHED;
        case OperationStatus.OPERATION_NO_AUTH_CHANGE_STAGE:
          return IconType.NOT_ALLOWED;
        case OperationStatus.OPERATION_REJECTED:
          return IconType.REJECTED;
      }
    },
  },
};
</script>

<style scoped lang="less">
.steps {
  padding: 20px;
  border-radius: 8px;
  background-color: var(--blueCardBackgroundColor);
  overflow: hidden;
  text-align: center;
}

.step-items {
  display: flex;
  margin-bottom: 20px;

  .step {
    padding-top: 18px;
    display: flex;
    align-items: center;
    flex-basis: 1;

    &:last-child {
      flex-basis: auto !important;
      flex-shrink: 0;
    }

    .step-main {
      position: relative;

      .step-title {
        position: absolute;
        white-space: nowrap;
        top: -15px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 12px;
      }
    }

    .step-line {
      width: 100%;
      height: 2px;
      background-color: #ccc;
    }
  }
}

.termination-step {
  display: inline-block;
  font-size: 0;

  .step-title {
    margin-top: 3px;
    font-size: 12px;
  }
}
</style>
