<template>
  <div>
    <van-tabbar inactive-color="var(--titleColor)" active-color="var(--titleColor)">
      <van-tabbar-item
          v-for="(item, index) in tabBar.list1"
          :key="index"
          @click="item._callback"
      >
        <span>{{ item.name }}</span>

        <template #icon>
          <svg v-if="item.icon.includes('#icon-hoss')" class="icon" aria-hidden="true">
            <use :xlink:href="item.icon"></use>
          </svg>
          <van-icon :name="item.icon"/>
        </template>
      </van-tabbar-item>
    </van-tabbar>

    <van-action-sheet
        v-model="showAction"
        :close-on-click-overlay="true"
        :actions="tabBar.list2"
        cancel-text="取消"
        @select="onSelect"
    />
  </div>
</template>

<script>
export default {
  props: ["stage", "state"],
  data() {
    return {
      showAction: false,
      more: {
        name: "更多",
        icon: "ellipsis",
        _callback: () => (this.showAction = true),
      },
      allTabBar: [
        {
          name: "组建团队",
          icon: "#icon-hoss-svg6",
          stages: [0],
          sort: 3,
          _callback: () => this.$emit("clickTabBar", {event: "createTeam"}),
        },
        {
          name: "查看档案",
          icon: "#icon-hoss-svg5",
          stages: [0, 1, 2, 3],
          sort: 5,
          _callback: () => this.$emit("clickTabBar", {event: "archives"}),
        },
        {
          name: "重要节点",
          icon: "#icon-hoss-svg7",
          stages: [0, 1, 2, 3],
          sort: 6,
          _callback: () => this.$emit("clickTabBar", {event: "important"}),
        },
        {
          name: "审批记录",
          icon: "#icon-hoss-svg7",
          stages: [0, 1, 2, 3],
          sort: 7,
          _callback: () => this.$emit("clickTabBar", {event: "approvalRecordList"}),
        },
        {
          name: "团队调整",
          icon: "#icon-hoss-svg6",
          stages: [1, 2, 3],
          sort: 8,
          _callback: () => this.$emit("clickTabBar", {event: "changeTeam"}),
        },
        {
          name: "实施记录",
          icon: "#icon-hoss-svg4",
          stages: [2, 3],
          sort: 0,
          _callback: () => this.$emit("clickTabBar", {event: "record"}),
        },
        {
          name: "复盘总结",
          icon: "#icon-hoss-svg2",
          stages: [2, 3],
          sort: 1,
          _callback: () => this.$emit("clickTabBar", {event: "reviewSummary"}),
        },
        {
          name: "粉丝群报告",
          icon: "#icon-hoss-svg3",
          stages: [2, 3],
          sort: 2,
          _callback: () => this.$emit("clickTabBar", {event: "fanGroupReport"}),
        },
        {
          name: "破冰记录",
          icon: "#icon-hoss-svg1",
          stages: [1],
          sort: 4,
          _callback: () => this.$emit("clickTabBar", {event: "icebreaking"}),
        },
      ],
    };
  },
  computed: {
    tabBar() {
      const tabBar = {
        list1: [],
        list2: [],
      };
      const list = this.allTabBar
          .filter((item) => {
            return item.stages.includes(this.stage);
          })
          .sort((a, b) => {
            return a.sort - b.sort;
          });
      if (list.length > 4) {
        const list2 = list.splice(3, list.length);
        tabBar.list1 = [...list, this.more];
        tabBar.list2 = list2;
      } else {
        tabBar.list1 = list;
      }
      return tabBar;
    },
  },
  methods: {
    onSelect(e) {
      this.showAction = false;
      e._callback();
    },
  },
};
</script>

<style lang="less" scoped>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
