<template>
  <div class="client-info">
    <div class="name">
      <span>{{ clientInfo.nickName }}</span>
      <span v-if="clientInfo.name">（{{ clientInfo.name }}）</span>
    </div>

    <div class="setting">
      <span v-if="!clientInfo.risk" @click="setRisk">设置风险</span>
      <template v-else>
        <van-tag plain round color="var(--mainColor)" size="mini">
          {{ getRiskName(clientInfo.risk) }}
        </van-tag>
        <svg class="icon ml7" aria-hidden="true" @click="delRisk">
          <use xlink:href="#icon-hoss-svg8"></use>
        </svg>
      </template>
      <van-icon
          v-if="clientInfo.hasOldRisk"
          class="ml7"
          name="eye-o"
          size="16"
          color="var(--mainColor)"
          @click="showRiskList"
      />
    </div>

    <div class="symptoms">
      <van-tag
          v-for="item in clientInfo.symptoms"
          :key="item"
          plain
          round
          color="var(--subTitleColor)"
          size="mini"
      >
        {{ item }}
      </van-tag>
    </div>

    <div class="team" v-if="clientInfo.groupShow == 2">
      <span>团队：</span>
      <span class="master">{{ clientInfo.master }}</span>
      <span class="consultantList">
                {{ clientInfo.consultantList }}
            </span>
    </div>
    <div v-else class="team" @click="$emit('createTeam')">
      <van-icon name="add-o" color="var(--mainColor)"/>
      组建团队
    </div>
    <div v-if="clientInfo.transferDate" class="transfer-notice" @click="
      $router.push(`/hoss/application?clientId=${$route.query.clientId}&applicationType=1&applicationId=${clientInfo.transferApplicationId}`)
">
      <state-icon type="6"/>
      <span class="transferDate">
                转二阶段申请已通过，预计{{ formatDate(clientInfo.transferDate, 'MM月dd日') }}变更阶段，点击查看
            </span>
    </div>
  </div>
</template>

<script>
import {delRisk} from "@/api/hoss/risk";
import StateIcon from "@/components/Apply/ApplyStateIcon.vue";
import {formatDate} from "element-ui/src/utils/date-util";

export default {
  components: {StateIcon},
  props: ["clientInfo"],
  data() {
    return {
      riskList: [],
    };
  },
  async created() {
    const {risk} = await this.$getDict();
    this.riskList = risk.children;
  },
  methods: {
    formatDate,
    getRiskName(code) {
      if (!code || !this.riskList?.length) return "";
      const item = this.riskList.find((item) => item.code == code);
      return item ? item.name : "";
    },
    setRisk() {
      if (this.clientInfo.state == 0) return this.$toast("请组建团队后进行操作");
      this.$router.push(`/hoss/setRisk?clientId=${this.$route.query.clientId}`);
    },
    async delRisk() {
      await delRisk({clientId: this.$route.query.clientId});
      this.$emit("delRisk");
    },
    showRiskList() {
      this.$router.push(`/hoss/riskList?clientId=${this.$route.query.clientId}`);
    },
  },
};
</script>

<style lang="less" scoped>
.client-info {
  .name {
    font-size: 28px;
    font-weight: 500;
    margin: 5px 0;
  }

  .setting {
    height: 18px;
    font-size: 12px;
    color: var(--mainColor);
    display: flex;
    align-items: center;
    margin: 5px 0;

    .ml7 {
      margin-left: 7px;
    }

    .van-tag {
      font-size: 8px;
      vertical-align: bottom;
    }

    .icon {
      width: 12px;
      height: 12px;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
      color: var(--mainColor);
    }
  }

  .symptoms {
    margin: -5px 0;

    .van-tag {
      font-size: 8px;
      vertical-align: bottom;
      margin: 5px 5px 5px 0;
    }
  }

  .team {
    font-size: 12px;
    margin: 5px 0;

    .master {
      color: var(--secondMainColor);
      margin-right: 5px;
    }
  }

  .transfer-notice {
    margin: 10px 0;

    .transferDate {
      font-size: 11px;
      color: var(--titleColor);
    }
  }
}


</style>
