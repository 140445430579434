<template>
  <div>
    <div v-if="clientInfo && stepData" class="stage-wrap">
      <div class="info-wrap">
        <client-info
            :client-info="clientInfo"
            @createTeam="createTeam"
            @delRisk="clientInfo.risk = null"
        />
        <steps :stepData="stepData" :stage="stageVO.stage" @clickStepItem="clickStepItem"/>
      </div>

      <van-tabs v-model="tabActive" :ellipsis="false">
        <van-tab
            v-if="stageVO.stage > 1"
            name="Implementation"
            title="实施记录"
            :dot="!!unCompleted[HossClientDomain.DOMAIN_EXEC_RECORD]"
        >
          <un-complete-cell
              name="实施记录"
              :list="unCompleted[HossClientDomain.DOMAIN_EXEC_RECORD]"
              @clickItem="toRecord($event)"
          />
          <record-list/>
        </van-tab>
        <van-tab
            v-if="stageVO.stage > 1"
            name="ReviewSummary"
            title="复盘总结"
            :dot="!!unCompleted[HossClientDomain.DOMAIN_REVIEW_SUMMARY]"
        >
          <un-complete-cell
              name="复盘总结"
              :list="unCompleted[HossClientDomain.DOMAIN_REVIEW_SUMMARY]"
              @clickItem="toSummary($event)"
          />
          <review-summary-list/>
        </van-tab>

        <van-tab
            v-if="stageVO.stage > 1"
            name="FanGroupReport"
            title="粉丝群报告"
            :dot="!!unCompleted[HossClientDomain.DOMAIN_FAN_GROUP_REPORT]"
        >
          <un-complete-cell
              name="粉丝群报告"
              :list="unCompleted[HossClientDomain.DOMAIN_FAN_GROUP_REPORT]"
              @clickItem="toFanApprove({ ...$event, clientId })"
          />
          <fan-group-report-list/>
        </van-tab>

        <van-tab title="基本信息" name="ClientRecord">
          <base-info/>
        </van-tab>

        <van-tab v-if="stageVO.stage > 0" title="破冰记录" name="Icebreaking">
          <ice-res-list/>
        </van-tab>
      </van-tabs>

      <tab-bar :stage="stageVO.stage" :state="state" @clickTabBar="clickTabBar"/>
    </div>
    <van-popup v-model="showTransferToStage2Dialog" position="center"
               class="van-popup-transfer2stage-confirm-dialog"
               round
               :overlay="true"
               :close-on-click-overlay="false"
    >
      <div class="dialog_title">
        转二阶段确认
      </div>
      <div class="dialog_content">
        <!--        确认后将转为二阶段，是否确认-->
        <!--        还可以点我延期日期 蓝色字-->
        <!--        或点这里取消转二阶段 红色字-->
        <div class="dialog_content_text">
          确认后将转为二阶段，是否确认
        </div>
        <div class="dialog_content_text">
          还可以点我<span class="dialog_content_text_delay"
                          @click="$router.push(`/hoss/application?clientId=${$route.query.clientId}&applicationType=1&applicationId=${clientInfo.transferApplicationId}`)"
        >延期日期
        </span>
        </div>
        <div class="dialog_content_text">
          或点这里<span class="dialog_content_text_cancel"
                        @click="$router.push(`/hoss/application?clientId=${$route.query.clientId}&applicationType=1&applicationId=${clientInfo.transferApplicationId}`)">取消转二阶段</span>
        </div>
      </div>
      <div class="dialog_bottom_actions">
        <!--        取消和确认 并排-->
        <div class="dialog_bottom_actions_cancel" @click="onTransferToStage2ButNotForTheMoment()">暂不处理</div>
        <div class="dialog_bottom_actions_confirm" @click="handleTransferToStage2(ExecutionStatus.CONFIRMED)">确认</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import ClientInfo from "./components/ClientInfo.vue";
import Steps from "./components/Steps.vue";
import TabBar from "./components/TabBar";
import UnCompleteCell from "./components/UnCompleteCell.vue";
import {iceToOneStage, queryClientDetail, queryClientStageChart, listUncompleted, handleTransfer,} from "@/api/hoss";
import {Dialog} from "vant";
import {ExecutionStatus, HossClientDomain, OperationStatus} from "@/api/consts";
import clientInfo from "@/views/hoss/stage-info/components/ClientInfo.vue";

export default {
  computed: {
    ExecutionStatus() {
      return ExecutionStatus
    },
    HossClientDomain() {
      return HossClientDomain
    }
  },
  components: {
    Steps,
    ClientInfo,
    TabBar,
    UnCompleteCell,
    // 异步引入
    BaseInfo: () => import("./components/TabItems/BaseInfoTab"),
    IceResList: () => import("./components/TabItems/IceResList"),
    recordList: () => import("./components/TabItems/recordList"),
    ReviewSummaryList: () => import("./components/TabItems/ReviewSummaryList"),
    FanGroupReportList: () => import("./components/TabItems/FanGroupReportList"),
  },
  data() {
    return {
      clientId: "",
      tabActive: "",
      clientInfo: undefined,
      stepData: undefined,
      stageVO: undefined,
      milepostId: undefined,
      unCompleted: {},
      showTransferToStage2Dialog: false,
      transferToStage2NeedConfirmButNotForTheMoment: false,
    };
  },
  async created() {
    const {clientId} = this.$route.query;
    this.clientId = clientId;
    this.queryClientInfo();
  },
  beforeRouteEnter(to, from, next) {
    console.log("to =>", to);
    console.log("from =>", from);
    next((vm) => {
      vm.tabActive = from.name || "";
    });
  },
  beforeRouteLeave(to, from, next) {
    console.log("beforeRouteLeave");
    console.log("to =>", to);
    console.log("from =>", from);
    const whiteList = ["ApprovalRecordPage", "ImportantNode", "ClientRecord", "ApplicationPage", "ClientList"];
    if (whiteList.includes(to.name)) {
      next();
      return;
    }
    if (this.transferToStage2NeedConfirmButNotForTheMoment) {
      this.$toast("当前尚未确认转二阶段，暂时无法操作！\n如需确认操作请重新进入该客户详情");
      next(false);
    } else {
      next();
    }
  },
  methods: {
    // onRefreshPage() {
    //   window.location.reload();
    // },
    // 获取 客户阶段 客户信息
    async queryClientInfo() {
      await Promise.all([
        this.getClientInfo(this.clientId),
        this.getClientStage(this.clientId),
        this.getUnCompleted(this.clientId),
      ]);
      this.$nextTick(() => {
        this.setTabContentScroll();
      });
    },
    onTransferToStage2ButNotForTheMoment() {
      this.showTransferToStage2Dialog = false;
      this.transferToStage2NeedConfirmButNotForTheMoment = true;
    },
    handleTransferToStage2(executionResult) {
      // (applicationId, scheduledDate, executionResult) {
      handleTransfer(
          this.clientInfo.transferApplicationId,
          null,
          executionResult
      ).then((res) => {
        console.log("res =>", res);
        this.queryClientInfo();
      }).catch((err) => {
        console.log("err =>", err);
        this.$toast.fail("处理客户转二阶段失败，请联系管理员确认问题！" + err.message);
      });
    },
    // 点击阶段
    clickStepItem({
                    state,
                    stage,
                    applicationType,
                    approveId = "",
                    operationStatus,
                    approveState,
                    currentStage,
                    applicationId = ""
                  }) {
      console.log("click step: ", state, applicationType);
      console.log("currentStage =>", currentStage);
      console.log("stage =>", stage);
      // operationStatus 操作状态  1.完成  2.未完成  3.禁用
      // if (currentStage.state === 5 ) {
      //   console.log("被驳回，当前state =>", currentStage.state);
      console.log("operationStatus =>", operationStatus);
      console.log("state =>", state);
      // return;
      // }
      if (operationStatus === OperationStatus.OPERATION_NOT_ALLOWED || operationStatus === OperationStatus.OPERATION_NO_AUTH_CHANGE_STAGE) return;
      if (stage === 3 || state === 5 || state === 6) {
        this.$router.push(
            `/hoss/application?clientId=${this.clientId}&applicationType=${applicationType}&applicationId=${applicationId}`
        );
        return;
      }
      if (stage === 1) {
        if (operationStatus !== 2) return;
        this.createTeam({stage: 1});
        return;
      }
      if (stage === 2) {
        if (currentStage.stage === 0) {
          this.createTeam({stage: 2});
        } else if (currentStage.stage === 1) {
          console.log("一阶段且需要确认");
          Dialog.confirm({
            title: "转一阶段确认",
            message: "请确认客户破冰成功可以进入一阶段，进入后无法再回到破冰阶段。",
            className: "to-transfer-2-dialog",
          })
              .then(async () => {
                await iceToOneStage(this.clientId);
                this.queryClientInfo();
              })
              .catch(() => {
                /* on cancel */
              });
        }
      }
      // switch (stage) {
      //   case 1: // 破冰
      //     this.createTeam({stage: 1});
      //     break;
      //   case 2: // 一阶段
      //     if (currentStage === 0) {
      //       this.createTeam({stage: 2});
      //     } else if (currentStage === 1) {
      //       console.log("一阶段且需要确认");
      //       Dialog.confirm({
      //         title: "转一阶段确认",
      //         message: "请确认客户破冰成功可以进入一阶段，进入后无法再回到破冰阶段。",
      //         className: "hoss-dialog",
      //       })
      //           .then(async () => {
      //             await iceToOneStage(this.clientId);
      //             this.queryClientInfo();
      //           })
      //           .catch(() => {
      //             /* on cancel */
      //           });
      //     }
      //     break;
      //   case 3: // 二阶段
      //   case 5: // 结单阶段
      //   case 6: // 终止
      //     if (operationStatus === 1 || operationStatus === 3) return;
      //     this.$router.push(
      //         `/hoss/application?clientId=${this.clientId}&applicationType=${applicationType}&applicationId=${applicationId}`
      //     );
      //     break;
      //   default:
      //     break;
      // }
    },
    // 点击底部tabbar
    clickTabBar({event}) {
      console.log("tabBar event: ", event);
      const {clientId, milepostId, stageVO} = this;
      switch (event) {
        case "createTeam": // 新建团队
          this.createTeam();
          break;

        case "changeTeam": // 团队调整
          if (this.clientInfo.groupShow === 3) return this.$toast("暂无权限调整团队");
          this.$router.push(`/hoss/changeTeam?clientId=${clientId}`);
          break;

        case "archives": // 查看档案
          this.$router.push(`/hoss/clientRecord?clientId=${clientId}`);
          break;

        case "icebreaking": // 破冰记录
          this.$router.push({
            path: "/hoss/icebreaking",
            query: {clientId},
          });
          break;

        case "record": // 实施总结
          this.toRecord({milepostId});
          break;

        case "reviewSummary": // 复盘总结
          this.toSummary({milepostId});
          break;

        case "fanGroupReport": // 粉丝群报告
          this.toFanApprove({milepostId, clientId});
          break;

        case "important": // 粉丝群报告
          this.$router.push({
            path: "/hoss/importantNode",
            query: {clientId, stage: stageVO.stage},
          });
          break;
        case "approvalRecordList": // 审批记录
          this.$router.push({
            path: "/hoss/approval-record-page",
            query: {clientId},
          });
          break;
        default:
          break;
      }
    },

    // 创建团队
    createTeam({stage = ""} = {}) {
      if (this.clientInfo.groupShow === 3) return this.$toast("暂无权限创建团队");
      this.$router.push({
        path: "/hoss/createTeam",
        query: {stage, clientId: this.clientId},
      });
    },

    // 跳转实施记录
    toRecord({milepostId = "", execDate}) {
      console.log(execDate);
      this.$router.push({
        path: "/hoss/implementation",
        query: {milepostId, date: execDate},
      });
    },
    // 跳转复盘总结
    toSummary({milepostId = "", execDate = ""}) {
      this.$router.push({
        path: "/hoss/reviewSummary",
        query: {milepostId, execDate},
      });
    },
    // 跳转粉丝群报告
    toFanApprove({milepostId = "", execDate = "", clientId = ""}) {
      this.$router.push({
        path: "/hoss/fanGroupReport",
        query: {milepostId, execDate, clientId},
      });
    },

    // 客户信息
    async getClientInfo(clientId) {
      const {value} = await queryClientDetail(clientId);
      this.clientInfo = {
        nickName: value.nickName ?? "",
        name: value.name ?? "",
        symptoms: value.symptoms?.split(","),
        master: value.master?.[0],
        consultantList: value.consultantList?.join(" "),
        groupShow: value.groupShow,
        risk: value.risk,
        stageWithResult: value.stageWithResult,
        hasOldRisk: value.hasOldRisk,
        transferDate: value.transferDate,
        transferApplicationId: value.transferApplicationId,
        toTransfer: value.toTransfer,
      };
      this.showTransferToStage2Dialog = this.clientInfo.toTransfer;
      this.stageVO = value.stage;
      this.state = value.state;
      this.milepostId = value.milepostId;
    },

    // 阶段信息
    async getClientStage(clientId) {
      // await queryClientStage(clientId);
      const {value} = await queryClientStageChart(clientId);
      this.stepData = value;
    },

    async getUnCompleted(clientId) {
      const {value} = await listUncompleted(clientId);
      console.log("未完成的任务: ", value);
      this.unCompleted = value;
    },

    // 设置tab内容固定高度
    setTabContentScroll() {
      const tab_content_ele = document.querySelector(".van-tabs__content");
      const tabBarEl = document.querySelector(".van-tabbar");

      const top = tab_content_ele.getBoundingClientRect().top; // tab页的内容，到页面顶部高度
      const tabBarHeight = tabBarEl?.getBoundingClientRect().height ?? 0; // tabbar的高度

      tab_content_ele.style.height = `calc(100vh - ${tabBarHeight}px - ${top}px)`;
      tab_content_ele.style.overflowY = `auto`;
    },
  },
};
</script>

<style scoped lang="less">
.stage-wrap {
  height: calc(100vh - 80px);
}

.info-wrap {
  padding: 14px 20px;
  margin: 10px;
  background-color: var(--cardBackgroundColor);
  border-radius: 8px;
}

/deep/ .van-tabs {
  margin: 0 10px;
  background-color: var(--cardBackgroundColor);
  border-radius: 8px 8px 0 0;
  overflow: hidden;

  .van-tabs__wrap {
    border-bottom: 1px solid var(--separatorColor);
    padding: 0 10px;

    .van-tabs__nav {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .van-tab {
    flex: 0 0 23.5%;
    padding: 0;

    .van-tab__text {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 2px;
    }
  }

  .van-tabs__content {
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .van-tabs__line {
    width: 60px;
  }

  .van-info--dot {
    top: 3px;
    right: -5px;
    border: none;
  }
}

/deep/ .van-tabbar-item__text {
  font-size: 11px;
  font-weight: 500;
}

.van-popup-transfer2stage-confirm-dialog {
  // 宽度
  width: 88%;

  .dialog_title {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 20px 0;
  }

  .dialog_content {
    padding: 0 20px 10px;
    font-size: 14px;
    color: var(--textColor);
    line-height: 0.8;
    // 居中
    text-align: center;


    .dialog_content_text {
      margin-bottom: 10px;

      .dialog_content_text_delay {
        color: #205af9;
      }

      .dialog_content_text_cancel {
        color: #e14440;
      }
    }
  }

  .dialog_bottom_actions {
    display: flex;
    justify-content: space-between;
    // 顶部一条线
    border-top: 1px solid var(--separatorColor);
    // 按钮各一半、文字居中


    .dialog_bottom_actions_cancel {
      // 文字居中
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48%;
      color: var(--titleColor);
    }

    .dialog_bottom_actions_confirm {
      background-color: var(--mainColor);
      width: 48%;
      height: 40px;
      font-size: 14px;
      color: white;
      // 文字居中
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}
</style>
