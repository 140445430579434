<template>
  <div>
    <van-cell
        v-for="item in list"
        :key="item.milepostId"
        class="tips"
        icon="warning"
        is-link
        @click="$emit('clickItem', item)"
    >
      {{ timeFormat(item.execDate, "YYYY-MM-DD") }} {{ name }}{{ item.id ? '未完成' : '未填写' }}，点击填写
    </van-cell>
  </div>
</template>

<script>
import {timeFormat} from "@/utils";

export default {
  props: ["list", "name"],
  methods: {
    timeFormat,
  },
};
</script>

<style scoped lang="less">
.tips.van-cell {
  font-size: 12px;
  padding: 6px 10px;
  border-radius: 8px;
  background-color: var(--blueCardBackgroundColor);
  margin-bottom: 10px;

  .van-cell__left-icon {
    color: var(--red);
  }
}
</style>
